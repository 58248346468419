<template>
  <group-by-select :value="groupBy" :items="filterTypes" @change-group="changeGroupBy($event)" />
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import { adFormats } from '@clickadilla/components/constants/ad-formats.js'
  import GroupBySelect from './GroupBySelect.vue'

  export default {
    name: 'GroupBy',
    components: {
      GroupBySelect
    },
    computed: {
      ...mapState('campaignsStatistics', ['groupByOptions', 'groupBy']),
      ...mapState('settings', ['adNetwork']),
      ...mapGetters('settings', ['getAdFormatTypesByAdNetwork']),
      ...mapGetters('personal', ['userHasProviderSubscription']),
      types() {
        return Object.entries(this.groupByOptions).reduce((acc, [optionKey, option]) => {
          const labelValue = option.type === adFormats.IN_PAGE_AD && this.adNetwork.settings.renameInPageFormat ? 'tg_interstitial' : option.label
          acc.push({
            value: optionKey,
            label: this.$t(`campaign_statistics.tab_labels.${labelValue}`),
            type: option.type,
            ...option.startGroup && {
              startGroup: option.startGroup
            },
            ...option.icon && {
              icon: option.icon
            }
          })
          return acc
        }, [])
      },
      filterTypes() {
        const filteredAdFormats = this.types.filter(({ type }) => this.getAdFormatTypesByAdNetwork.includes(type) || !type)
        return this.userHasProviderSubscription
          ? filteredAdFormats
          : this.trafficProviderFilter(filteredAdFormats)
      }
    },
    async created() {
      await this.fetchUserProfile()
    },
    methods: {
      ...mapActions('campaignsStatistics', ['fetchStatistics', 'setGroupBy', 'reset', 'resetStatisticsItems']),
      ...mapActions('personal', ['fetchUserProfile']),
      changeGroupBy(groupBy) {
        this.setGroupBy(groupBy)
        this.resetStatisticsItems()
        this.fetchStatistics()
      },
      trafficProviderFilter(types) {
        return types.filter((type) => type.value !== 'trafficProvider')
      }
    }
  }
</script>
