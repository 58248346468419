var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isMobileOrTablet)?_c('div',{staticClass:"d-flex overflow-auto"},_vm._l((_vm.items),function(item,index){return _c('c-btn',{key:item.value,class:[
        'ml-1 mb-4',
        { 'ml-4': item.startGroup && index !== 0 },
        { 'primary white--text': item.value === _vm.currentGroup }
      ],attrs:{"height":"32","depressed":""},on:{"click":function($event){_vm.currentGroup = item.value}}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1):_c('v-tabs',{attrs:{"show-arrows":"","prev-icon":"$arrow-left","next-icon":"$arrow-right","grow":"","height":"32"},model:{value:(_vm.currentGroup),callback:function ($$v) {_vm.currentGroup=$$v},expression:"currentGroup"}},_vm._l((_vm.items),function(item,index){return _c('c-btn',{key:item.value,class:[
        'ml-1 black--text font-weight-regular',
        { 'ml-4': item.startGroup && index !== 0 },
        { 'primary white--text': item.value === _vm.currentGroup }
      ],attrs:{"height":"32","depressed":""},on:{"click":function($event){_vm.currentGroup = item.value}}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }