<template>
  <c-autocomplete
    :value="selectedCampaigns"
    :label="$t('campaign_statistics.campaign_name')"
    :placeholder="$t('campaign_statistics.select_campaign_name')"
    item-text="text"
    item-value="id"
    :all-selected-is-active="false"
    return-object
    :loading="campaignIsLoading"
    :items="mappedCampaigns"
    hide-details
    clearable
    :search-input="search"
    @change="selectCampaign($event)"
    @blur="closeList()"
    @update:search-input="searchCampaigns($event)"
  >
    <template #append-item>
      <div v-if="!search" v-intersect="endIntersect" class="text-center">
        <v-progress-circular v-if="campaignIsLoading" :size="20" color="primary" indeterminate />
      </div>
    </template>
  </c-autocomplete>
</template>

<script>
  import debounce from 'debounce'
  import CAutocomplete from '@clickadilla/components/ui/CAutocomplete.vue'
  import { mapActions, mapState } from 'vuex'
  import { campaignsRepository } from '@/services/repository-factory.js'
  import Campaign from '@/services/classes/Campaign.js'
  import handleError from '@/services/handleErrors.js'

  export default {
    name: 'CampaignsSelect',
    components: { CAutocomplete },
    props: {
      campaignIds: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        campaignsList: [],
        campaignIsLoading: false,
        options: {
          sortBy: ['id'],
          sortDesc: [true],
          page: 0,
          itemsPerPage: 15
        },
        search: null,
        pageCount: 1,
        debounceFetchTable: null
      }
    },
    computed: {
      ...mapState('campaignsStatistics', ['selectedCampaigns', 'adFormatsIds', 'campaignStatuses']),
      mappedCampaigns() {
        return this.campaignsList.map((campaign) => ({
          ...campaign,
          text: `${campaign.name} (${campaign.id})`
        }))
      }
    },
    watch: {
      adFormatsIds() {
        this.campaignsList = [...this.selectedCampaigns]
        this.options.page = 0
        this.pageCount = 1
      },
      campaignStatuses() {
        this.campaignsList = [...this.selectedCampaigns]
        this.options.page = 0
        this.pageCount = 1
      }
    },
    async created() {
      this.debounceFetchTable = debounce(this.fetchCampaignsList, 400)
      if (this.campaignIds.length) {
        this.options.page = 1
        this.options.itemsPerPage = -1
        await this.fetchCampaignsList(this.campaignIds)
        this.selectCampaign(this.campaignsList)
        this.options.page = 0
        this.options.itemsPerPage = 15
        this.pageCount = 1
      }
    },
    methods: {
      ...mapActions('campaignsStatistics', ['setSelectedCampaigns']),
      async fetchCampaignsList(campaignIds = []) {
        const params = {
          headers: [
            { value: 'id', searchable: !!campaignIds.length },
            { value: 'ad_format_id', searchable: true },
            { value: 'status', searchable: true },
            { value: 'common_name', searchable: true }
          ],
          options: this.options,
          search: {
            ad_format_id: this.adFormatsIds,
            status: this.campaignStatuses,
            ...this.search && { common_name: this.search.trim() },
            ...!!campaignIds.length && { id: campaignIds }
          }
        }
        this.campaignIsLoading = true
        try {
          const response = await campaignsRepository.campaignsTable(params)
          this.campaignsList = this.campaignsList.concat(
            response.items.map((campaign) => new Campaign(campaign))
          )
          this.pageCount = Math.ceil(response.totalItemsCount / this.options.itemsPerPage)
          this.campaignIsLoading = false
        } catch (error) {
          handleError(error)
        }
      },
      selectCampaign(selectedCampaigns) {
        this.setSelectedCampaigns(selectedCampaigns)
        this.$emit('update')
      },
      searchCampaigns(search) {
        if (search) {
          this.search = search
          this.options.page = 1
          this.campaignsList = [...this.selectedCampaigns]
          this.debounceFetchTable()
        } else {
          this.closeList()
        }
      },
      closeList() {
        if (this.search) {
          this.search = null
          this.campaignsList = [...this.selectedCampaigns]
          this.options.page = 0
          this.pageCount = 1
        }
      },
      endIntersect(entries, observer, isIntersecting) {
        if (isIntersecting && !this.campaignIsLoading && this.options.page < this.pageCount) {
          this.campaignIsLoading = true
          this.options = { ...this.options, page: this.options.page + 1 }
          this.debounceFetchTable()
        }
      }
    }
  }
</script>
